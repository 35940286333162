<template>
  <div class="main-body">
    <topbar/>
    <router-view/>
  </div>
</template>

<script>
import Topbar from "../components/topbar";
export default {
  name: 'body',
  components: {
    Topbar
  }
}
</script>