<template>
  <div class="container">
    <div class="product">
      <div class="d-flex justify-content-between">
        <div class="title">
          {{ this.product.title }}
        </div>
        <div class="delete-images">
          <button class="btn btn-danger" v-on:click="deleteImage()">Удалить фотографии товара</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="product-category-path">
            <tree
                :data="categories"
                :options="options"
                ref="tree"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="card mt-2">
            <div class="card-header">
              Цены товара
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label>Цена</label>
                    <input type="text" class="form-control" v-model="item.productsAvailables[0].price">
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>Старая цена</label>
                    <input type="text" class="form-control" v-model="item.productsAvailables[0].old_price">
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>Акционная цена</label>
                    <input type="text" class="form-control" v-model="item.productsAvailables[0].normal_old_price">
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>Яндекс цена</label>
                    <input type="text" class="form-control" v-model="item.productsAvailables[0].yandex_price">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card mt-2">
            <div class="card-header">
              Свойства товара
            </div>
            <div class="card-body">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="property-1" :checked="parseInt(item.freeze)"
                       @change="changeProperty('freeze')">
                <label class="form-check-label" for="property-1">Свежемороженный</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="property-2" :checked="parseInt(item.new)"
                       @change="changeProperty('new')">
                <label class="form-check-label" for="property-2">Новинка</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="property-3" :checked="parseInt(item.week)"
                       @change="changeProperty('week')">
                <label class="form-check-label" for="property-3">Товар недели</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="property-4" :checked="parseInt(item.preparation)"
                       @change="changeProperty('preparation')">
                <label class="form-check-label" for="property-4">Заготовка</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="property-5" :checked="parseInt(item.grain)"
                       @change="changeProperty('grain')">
                <label class="form-check-label" for="property-5">Зерновой</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="property-6" :checked="parseInt(item.seafood)"
                       @change="changeProperty('seafood')">
                <label class="form-check-label" for="property-6">Живые морепродукты</label>
              </div>
            </div>
          </div>
          <div class="upload-image">
            <div class="text-center">
              <img :src="'https://api.seasonmarket.ru/' + item.eco_medium_image" v-if="item.eco_medium_image"/>
              <img :src="'https://api.seasonmarket.ru/' + item.eco_big_image" v-else/>
            </div>
            <div class="form-group">
              <label class="form-label">Фото</label>
              <input class="form-control" type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
              <div class="col-12 mt-2">
                <button class="btn btn-success" v-on:click="submitFile()">Загрузить</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group mt-3">
        <label class="mb-1">Название</label>
        <input type="text" class="form-control" v-model="item.title"/>
      </div>
      <div class="form-group mt-3">
        <label class="mb-1">Полное описание</label>
        <quill-editor v-model="item.description"/>
      </div>
      <div class="form-group mt-3">
        <label class="mb-1">Тип товара в системе OZON</label>
        <select class="form-select" v-model="item.ozon_type_id" v-on:change="onChangeOzonType">
          <optgroup v-for="group in this.ozonCategories" :label="group.name" v-bind:key="group.id">
            <option v-for="option in group.children" :value="option.id" v-bind:key="option.id">
              {{ option.name }}
            </option>
          </optgroup>
        </select>
      </div>
      <hr>
      <h3>Атрибуты OZON</h3>
      <div class="form-group mt-3" v-for="attribute in this.ozonAttributes" v-bind:key="attribute.id">
        <label class="mb-1">{{ attribute.name }}</label>
        <input type="text" class="form-control" v-if="attribute.field_type === 'input'" v-model="selectAttributes[attribute.id]"/>

        <multiselect v-if="attribute.field_type === 'select'" v-model="selectAttributes[attribute.id]" :options="attribute.values" placeholder="Выберите" label="value"
                     track-by="id"></multiselect>
        <!--
        <multiselect v-if="attribute.field_type === 'select'" v-model="selectAttributes" :options="attribute.values"
                     placeholder="Начните поиск чтобы увидеть клиентов"
                     label="value" track-by="id" :searchable="true"
                     :internal-search="false" :options-limit="300"
                     :limit="12"></multiselect> -->

      </div>
      <button class="btn btn-success mt-4 mb-3" type="button" v-on:click="saveProduct" style="float:right;">Сохранить
      </button>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import axios from "axios";
import Multiselect from "vue-multiselect";

export default {
  name: 'view-product',
  data: function () {
    return {
      id: this.$route.params.id,
      item: [],
      file: null,
      image: null,
      options: {
        checkbox: true
      },
      test: null,
      attributes: [],
      selectAttributes: [],
    }
  },
  computed: {
    ...mapGetters(['product', 'ozonCategories', 'ozonAttributes', 'ozonAttributeSelectedValues']),
    categories: function () {
      return Object.entries(this.item.cats).map(entry => entry[1]);
    },
  },
  beforeUpdate() {
    this.item = this.product;
    this.selectAttributes = this.ozonAttributeSelectedValues;
  },
  async mounted() {
    await this.getProduct();
    this.getOzonCategories();
    let self = this;
    setTimeout(function () {
      self.getOzonAttributes();
      self.getOzonAttributeSelectedValuesValues()
    }, 1000);
  },
  methods: {
    onChangeOzonType: function () {
      let self = this;
      setTimeout(function () {
        self.getOzonAttributes();
        self.getOzonAttributeSelectedValuesValues()
      }, 1000);
    },
    getOzonAttributeSelectedValuesValues: function () {
      this.$store.dispatch('getOzonAttributeSelectedValues', this.id);
    },
    deleteImage: function () {
      this.$store.dispatch('deleteImageProduct', {'id': this.id});
    },
    changeProperty: function (value) {
      this.$store.dispatch('changeProperty', {'id': this.id, 'property': value});
    },
    getProduct: function () {
      this.$store.dispatch('getProduct', this.id);
    },
    getOzonCategories: function () {
      this.$store.dispatch('getOzonCategories');
    },
    getOzonAttributes: function () {
      if (this.item.ozon_type_id !== undefined) {
        let self = this;
        let category = this.ozonCategories.filter((group) => group.children.filter((item) => item.id == self.item.ozon_type_id).length > 0)
        //console.log(category[0].name)
        this.$store.dispatch('getOzonAttributes', {
          categoryId: category[0].id,
          typeId: this.item.ozon_type_id,
          productId: this.item.id,
        });
      }
    },
    saveProduct: function () {
      let tree = this.$refs.tree.findAll({state: {checked: true}});
      this.item.selectCats = tree.map(function (item) {
        return item.id;
      }).join(",");

      this.$store.dispatch('saveProduct', this.item);
      let self = this;
      let category = this.ozonCategories.filter((group) => group.children.filter((item) => item.id == self.item.ozon_type_id).length > 0)
      let form = {
        itemId: this.item.id,
        typeId: this.item.ozon_type_id,
        categoryId: category[0].id,
        attributes: []
      };

      Object.keys(this.selectAttributes).forEach(function(key) {
        form.attributes.push({key: key, value: self.selectAttributes[key]})
      });


      this.$store.dispatch('saveOzonAttributesProduct', form);
    },
    submitFile() {
      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('id', this.item.id);
      axios.post('/product/image',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(function () {
        window.location.reload();
      }).catch(function (e) {
        console.log(e)
      });
    },
    /*
      Handles a change on the file upload
    */
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    }
  },
  components: {
    Multiselect
  }
}
</script>
