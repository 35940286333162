<template>
  <section class="main">
    <div class="d-flex flex-wrap align-items-center justify-content-between">
      <div class="table-header">Список товаров для интеграции MultiBonus</div>
    </div>
    <div class="form-filter">
      <div class="row">
        <div class="col-md-2">
          <div class="form-group">
            <label class="form-label">Выберите категорию
              <vue-custom-tooltip label="Выберите категорию и начните заполнять товары"><i
                  class="far fa-question-circle"></i>
              </vue-custom-tooltip>
            </label>
            <select class="form-select" v-model="data.category_id">
              <option value="0" :disabled="true">Выберите категорию</option>
              <option v-for="item in treeList" :key="item.id" :value="item.id">{{ item.name }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <table class="table table-hover">
      <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Добавлен</th>
        <th scope="col">Название</th>
        <th scope="col">URL</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in listProductsInfo" :key="item.id">
        <th scope="row">{{ index + 1 }}</th>
        <td>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" @change="addProduct(item.id)"
                   :checked="parseInt(item.multibonus)">
          </div>
        </td>
        <td>{{ item.title }}</td>
        <td><a class="link-info" :href="'https://seasonmarket.ru/product/' + item.url" target="_blank">{{ item.url }}</a></td>
      </tr>
      </tbody>
    </table>
    <div class="row">
      <div class="col text-center">
        <a class="btn btn-primary" @click="getMore">Загрузить ещё</a>
      </div>
    </div>
    <create-mb-category/>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import CreateMbCategory from "../../components/modal/multibonus/createCategory";
import Vue from "vue";

export default {
  name: 'list-products',
  components: {CreateMbCategory},
  data: function () {
    return {
      filter: {
        limit: 12,
      },
      data: {
        category_id: 0,
        item_id: 0,
      }
    }
  },
  computed: mapGetters(['treeList', 'listProductsInfo']),
  async mounted() {
    this.getTreeList();
    this.getListProducts();
  },
  methods: {
    addProduct: function (id) {
      this.data.item_id = id;
      this.$store.dispatch('saveToMultiBonus', this.data).then((res) => {
        if(res.data.result === 'ok'){
          Vue.$toast.success(res.data.message);
        }else{
          Vue.$toast.error(res.data.message);
        }
      });
    },
    getMore: function () {
      this.filter.limit += 12;
      this.getListProducts();
    },
    getTreeList: function () {
      this.$store.dispatch('getMBCategory')
    },
    getListProducts: function () {
      this.$store.dispatch('getMBProducts', this.filter);
    },
  }
}
</script>