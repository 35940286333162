import Vue from 'vue';
import Router from 'vue-router';
import store from './store/store.js';

// views
import auth from './views/auth/login';
import index from './views/index';
import listUser from './views/users/list-users';
import viewUser from './views/users/view-user';
import listQuestion from './views/users/list-question';
import listProduct from './views/products/list-product';
import viewProduct from './views/products/view-product';
import listOrder from './views/orders/list-order';
import createOrder from './views/orders/create-order';
import viewOrder from './views/orders/view-order';
import listBanner from './views/banners/list-banners';
import createBanner from './views/banners/create-banner';
import editBanner from './views/banners/edit-banner';
import call from './views/call/list-call';
import callStats from './views/call/list-call-stats';
import listCategory from './views/category/list-category';
import viewCategory from './views/category/view-category';
import editCategory from './views/category/edit-category';
import editSubCategory from './views/category/edit-sub-category';
import settings from "./views/settings/settings";
import promocodes from "./views/promocodes/promocodes";
import listMailing from "./views/mailing/list-mailing";
import listLetter from "./views/mailing/list-letter";
import listLetterTemplate from "./views/mailing/list-letter-template";
import designerLetter from "./views/mailing/designer-letter";
import designerLetterTemplate from "./views/mailing/designer-letter-template";
import designerMailing from "./views/mailing/designer-mailing";
import profile from "./views/profile/profile";
import profileSettings from "./views/profile/settings";
import screenCall from "./views/call/screen-call";
import screenAnalytics from "./views/call/screen-analytics";
import mailingCreate from "./views/mailing/maillingCreate";
import listCategoryMB from "./views/multibonus/list-category";
import listProductsMB from './views/multibonus/list-products';

Vue.use(Router)

let router = new Router({
    mode: 'history',
    routes: [
        {
          path: '/auth',
          name: 'login',
          component: auth,
          meta: {
              layout: 'empty',
              requiresAuth: false,
          }
        },
        {
            path: '/',
            name: 'index',
            component: index,
            meta: {
                layout: 'main',
                requiresAuth: true
            }
        },
        {
            path: '/list-users',
            name: 'list-users',
            component: listUser,
            meta: {
                layout: 'main',
                requiresAuth: true
            }
        },
        {
            path: '/view-user/:id',
            name: 'view-user',
            component: viewUser,
            props: true,
            meta: {
                layout: 'main',
                requiresAuth: true
            }
        },
        {
            path: '/list-question',
            name: 'list-question',
            component: listQuestion,
            meta: {
                layout: 'main',
                requiresAuth: true
            }
        },
        {
            path: '/list-products',
            name: 'list-products',
            component: listProduct,
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/view-product/:id',
            name: 'view-product',
            component: viewProduct,
            props: true,
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/list-orders',
            name: 'list-orders',
            component: listOrder,
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/create-order',
            name: 'create-order',
            component: createOrder,
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/view-order/:id',
            name: 'view-order',
            component: viewOrder,
            props: true,
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/list-banners',
            name: 'list-banners',
            component: listBanner,
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/create-banner',
            name: 'create-banner',
            component: createBanner,
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/edit-banner/:id',
            name: 'edit-banner',
            component: editBanner,
            props: true,
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/list-call',
            name: 'call',
            component: call,
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/call-stats',
            name: 'call-stats',
            component: callStats,
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/screen-call',
            name: 'screen-call',
            component: screenCall,
            meta: {
                layout: 'free',
                requiresAuth: true
            },
        },
        {
            path: '/screen-analytics',
            name: 'screen-analytics',
            component: screenAnalytics,
            meta: {
                layout: 'free',
                requiresAuth: true
            },
        },
        {
            path: '/list-category',
            name: 'list-category',
            component: listCategory,
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/view-category/:id',
            name: 'view-category',
            component: viewCategory,
            props: true,
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/edit-category/:id',
            name: 'edit-category',
            component: editCategory,
            props: true,
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/edit-sub-category/:id',
            name: 'edit-sub-category',
            component: editSubCategory,
            props: true,
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/settings',
            name: 'settings',
            component: settings,
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/promocodes',
            name: 'promocodes',
            component: promocodes,
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/list-mailing',
            name: 'list-mailing',
            component: listMailing,
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/mailing/create',
            name: 'mailing-create',
            component: mailingCreate,
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/list-letter',
            name: 'list-letter',
            component: listLetter,
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/list-letter-template',
            name: 'list-letter-template',
            component: listLetterTemplate,
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/designer-letter/:id?',
            name: 'designer-letter',
            props: true,
            component: designerLetter,
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/designer-letter-template/:id?',
            name: 'designer-letter-template',
            props: true,
            component: designerLetterTemplate,
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/designer-mailing/:id?',
            name: 'designer-mailing',
            props: true,
            component: designerMailing,
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/profile',
            name: 'profile',
            component: profile,
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/profile/settings',
            name: 'profile-settings',
            component: profileSettings,
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/multibonus/list',
            name: 'listCategoryMB',
            component: listCategoryMB,
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
        {
            path: '/multibonus/products',
            name: 'listProductsMB',
            component: listProductsMB,
            meta: {
                layout: 'main',
                requiresAuth: true
            },
        },
    ]
})

router.beforeEach((to, from, next) => {
    if (window.callScreenInterval) {
        clearInterval(window.callScreenInterval)
    }
    
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next()
            return
        }
        next('/auth')
    } else {
        next()
    }
});

export default router
