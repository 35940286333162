<template>
  <div class="modal-backdrop" v-if="this.$store.state.modal.mbCreateCategory">
    <div class="modal-mask" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Создание категории / подкатегории</h5>
            <button type="button" class="btn-close" @click="close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label class="col-form-label">Название</label>
              <input type="text" class="form-control" placeholder="Название" v-model="category.name">
            </div>
            <div class="mb-3">
              <label class="col-form-label">Выберите категорию (если создаете подкатегорию)</label>
              <select class="form-select" v-model="category.category_id">
                <option value="0" :disabled="true">Выберите категорию</option>
                <option v-for="item in parentList" :key="item.id" :value="item.id">{{ item.name }}</option>
              </select>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="close">Закрыть</button>
            <button type="button" class="btn btn-primary" @click="createCategory">Создать</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import {mapGetters} from "vuex";

export default {
  name: 'create-mb-category',
  data: function () {
    return {
      category: {
        name: '',
        category_id: 0,
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getParentCategory();
    });
  },
  computed: mapGetters(['parentList']),
  methods: {
    getParentCategory: function () {
      this.$store.dispatch('getMBParentCategory');
    },
    createCategory: function () {
      let $this = this;
      this.$store.dispatch('createMBCategory', this.category).then((res) => {
        if(res.data.result === 'ok'){
          $this.category.name = '';
          $this.category.category_id = 0;
          this.$store.dispatch('getMBParentCategory');
          this.$store.dispatch('getMBCategory');
          Vue.$toast.success(res.data.message);
          this.close();
        }else{
          Vue.$toast.error(res.data.message);
        }
      });
    },
    close: function () {
      this.$store.state.modal.mbCreateCategory = false;
    },
  },
};
</script>
