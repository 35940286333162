import axios from 'axios';
import router from "../../../router";
import Vue from "vue";

export default {
    actions: {
        async getOrderList(ctx, filter) {
            const res = await axios.get('/order/list?limit=' + filter.limit
                + '&status=' + filter.orderId
                + '&status=' + filter.status
                + '&search=' + filter.search
                + '&orderId=' + filter.orderId
                + '&typePayment=' + filter.type_payment
                + '&statusPayment=' + filter.status_payment
                + '&source=' + filter.source
                + '&dateCreateBefore=' + filter.dateCreateBefore
                + '&dateCreateAfter=' + filter.dateCreateAfter
                + '&dateDelivery=' + filter.dateDelivery
            );
            ctx.commit('updateOrderList', res.data.data);
        },
        async getOrderExport(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.post('/order/export', data).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        async getOrder(ctx, orderId) {
            await axios.get('/order/get?id=' + orderId).then(async res => {
                ctx.commit('updateOrder', res.data.data);
            });
        },
        async createOrder(ctx, data) {
            const res = await axios.post('/order/create', data);
            if (res.data.result == 'ok') {
                router.go(-1);
                Vue.$toast.success(res.data.message);
            } else {
                Vue.$toast.error(res.data.message);
            }
        },
    },
    mutations: {
        updateOrderList(state, data) {
            state.orders = data.orders;
            state.totalCount = data.totalCount;
        },
        updateOrder(state, data) {
            state.order = data.order;
        },
    },
    state: {
        orders: [],
        order: [],
        totalCount: 0,
    },
    getters: {
        orderList(state) {
            return state.orders;
        },
        orderCount(state) {
            return state.totalCount;
        },
        order(state) {
            return state.order;
        }
    }
}
