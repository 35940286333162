<template>
  <section class="main">
    <div class="d-flex flex-wrap align-items-center justify-content-between">
      <div class="table-header">Редактирование категорию: {{ cat.name }}</div>
      <router-link class="btn btn-outline-primary" to="/list-category">Назад</router-link>
    </div>
    <div class="form-group">
      <label class="form-label">Название</label>
      <input type="text" class="form-control" placeholder="Название" v-model="cat.name">
    </div>
    <div class="form-group mt-3">
      <label class="form-label">Описание браузера</label>
      <input type="text" class="form-control" placeholder="Описание браузера" v-model="cat.title_browser">
    </div>
    <div class="form-group mt-3">
      <label class="mb-1">Полное описание</label>
      <quill-editor v-model="cat.desc"/>
    </div>
    <div class="form-group mt-3">
      <label class="form-label">Мета описание</label>
      <input type="text" class="form-control" placeholder="Мета описание" v-model="cat.meta">
    </div>
    <div class="form-group mt-3">
      <label class="form-label">Фотография для сайта</label>
      <input type="file" class="form-control" ref="web" v-on:change="handleFileUploadWeb()"/>
    </div>
    <div class="form-group mt-3">
      <label class="form-label">Фотография для приложения тёмная</label>
      <input type="file" class="form-control" ref="app_black" v-on:change="handleFileUploadMobBlack()"/>
    </div>
    <div class="form-group mt-3">
      <label class="form-label">Фотография для приложения белая</label>
      <input type="file" class="form-control" ref="app_white" v-on:change="handleFileUploadMobWhite()"/>
    </div>
    <div class="form-group mt-3">
      <label class="form-label">URL</label>
      <input type="text" class="form-control" placeholder="URL" v-model="cat.url">
      <div class="form-text">Просто текстом</div>
    </div>
    <div class="form-group mt-3">
      <label class="form-label">Порядок</label>
      <input type="number" class="form-control" placeholder="Порядок" :min="0" v-model="cat.sort">
      <div class="form-text">От 0 и выше</div>
    </div>
    <div class="form-group mt-3">
      <label class="form-label">Выберите категорию OZON</label>
      <select class="form-select" v-model="cat.ozon_category_id">
        <option v-for="option in this.ozonCategories" :value="option.id" v-bind:key="option.id">
          {{ option.name }}
        </option>
      </select>
    </div>
    <div class="d-flex justify-content-end mt-3">
      <button class="btn btn-success" @click="editCategory">Сохранить изменения</button>
    </div>
  </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'edit-category',
  props: ['id'],
  data: function () {
    return {
      cat: {
        id: this.id,
        name: '',
        title_browser: '',
        imageWeb: null,
        imageMobBlack: null,
        imageMobWhite: null,
        desc: '',
        meta: '',
        url: '',
        sort: 0,
        ozon_category_id: 0,
      },
    }
  },
  computed: {
    ...mapGetters(['category', 'ozonCategories']),
  },
  async mounted() {
    await this.getOzonCategories();
    await this.getCategoryInfo();
  },
  methods: {
    handleFileUploadWeb() {
      this.cat.imageWeb = this.$refs.web.files[0];
    },
    handleFileUploadMobBlack() {
      this.cat.imageMobBlack = this.$refs.app_black.files[0];
    },
    handleFileUploadMobWhite() {
      this.cat.imageMobWhite = this.$refs.app_white.files[0];
    },
    getCategoryInfo: function(){
      this.$store.dispatch('getCategoriesInfo', this.id).then(() => {
        this.cat.name = this.category.name;
        this.cat.title_browser = this.category.title_browser;
        this.cat.desc = this.category.desc;
        this.cat.meta = this.category.meta;
        this.cat.url = this.category.url;
        this.cat.sort = this.category.sort;
        this.cat.ozon_category_id = this.category.ozon_category_id;
      });
    },
    editCategory: function(){
      this.$store.dispatch('editCategory', this.cat);
    },
    getOzonCategories: function () {
      this.$store.dispatch('getOzonCategories');
    },
  }
}
</script>