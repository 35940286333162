<template>
  <div class="modal-backdrop" v-if="this.$store.state.modal.selectProducts">
    <div class="modal-mask" tabindex="-1">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Товары</h5>
            <button type="button" class="btn-close" @click="close"></button>
          </div>
          <div class="d-flex justify-content-between mx-3">
            <span class="form-group d-flex">
              <input type="text" class="form-control" name="search" placeholder="Поиск..." @keyup.enter="getProducts" v-model="searchVal"/>
              <button type="button" class="btn btn-primary" @click="getProducts">Найти</button>
            </span>
          </div>
          <div class="modal-body" :key="componentKey">
            <span v-if="BEData">
              <span v-html="msg"></span>
            </span>
            <span v-else>
              <h5 v-if="totalCount">
                Всего нашлось: <span class="badge bg-success">{{ totalCount }}</span>
              </h5>
              <table class="table tableProductsIdName">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Наименование</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="elem in data" :key="elem.id" @click="setProductId(elem.id)">
                    <td>{{ elem.id }}</td>
                    <td>{{ elem.title }}</td>
                  </tr>
                </tbody>
              </table>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'select-products',
  data: function(){
    return{
      componentKey: 0,
      searchVal:'',
      BEData:true,
      totalCount:false,
      msg:'Введите поисковую фразу, чтобы найти товары. Ограничение на вывод - 300 шт.<br>Можно искать по ID: "id:898"<br>Другие фильтры при поиске и экспорте пользователей, купивших определённый товар, не учитываются.<br>Ограничение на вывод до 99 тыс. пользователей.',
      data:[],
      selectedProductId:new Number(0),
    }
  },
  methods: {
    getProducts: function(){
      if(this.searchVal===''){
        this.$toast.info('Для начала необходимо ввести поисковый запрос.<br>Затем нажать на необходимый товар');
        return;
      }
      // Передаём поисковый запрос для товара на BE и добавляем список из товаров в таблицу
      this.$store.dispatch('getProductsBySearch', this.searchVal).then( res => {
        if(res?.data?.data && Object.keys(res.data.data)?.length > 0){
          // Получаем (res.data.data) >> {id: 5070, title: "Набор для салата Фунчоза"}
          this.data=res.data.data;
          this.BEData=false;
          this.totalCount=Object.keys(res.data.data).length;
          document.body.classList.add('bodyOvh');
        }else{
          this.BEData=true;
          this.totalCount=false;
        }
      });
    },
    setProductId(id) {
      this.$emit('setIDToParent', id);
      this.close();
    },
    close: function() {
      this.$store.state.modal.selectProducts = false;
      document.body.classList.remove('bodyOvh');
    },
  },
};
</script>

<style>
  .tableProductsIdName tr {
    cursor: pointer;
  }
  .bodyOvh{
    overflow: hidden;
  }
</style>