<template>
  <section class="main">
    <div class="d-flex flex-wrap align-items-center justify-content-between">
      <div class="table-header">Список категорий для интеграции с MultiBonus</div>
      <div class="btn-toolbar mt-3">
        <div class="btn-group me-2">
          <router-link class="btn btn-primary" to="/multibonus/products">Заполнить категорию товарами</router-link>
        </div>
        <div class="btn-group me-2">
          <a class="btn btn-success" @click="openCreateCategory">Создать категорию/подкатегорию</a>
        </div>
      </div>
    </div>
    <table class="table table-hover">
      <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Название</th>
        <th scope="col">Тип категории</th>
        <th scope="col">Активность</th>
        <th scope="col">Дата создания</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in treeList" :key="item.id">
        <th scope="row">{{ index + 1 }}</th>
        <td>{{ item.name }}</td>
        <td v-if="item.category_id > 0">Дочерняя категория</td>
        <td v-else>Родительская категория</td>
        <td style="color: green" v-if="item.active === 1">Включена</td>
        <td v-else style="color: red;">Выключена</td>
        <td>{{ item.date }}</td>
      </tr>
      </tbody>
    </table>
    <create-mb-category/>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import CreateMbCategory from "../../components/modal/multibonus/createCategory";

export default {
  name: 'list-products',
  components: {CreateMbCategory},
  data: function () {
    return {}
  },
  computed: mapGetters(['treeList']),
  async mounted() {
    this.getTreeList();
  },
  methods: {
    openCreateCategory: function () {
      this.$store.dispatch('openModal', 'mbCreateCategory');
    },
    getTreeList: function () {
      this.$store.dispatch('getMBCategory')
    },
  }
}
</script>