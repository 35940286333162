<template>
  <component :is="layout">
    <router-view/>
    <notifications/>
  </component>
</template>

<script>
import EmptyLayout from '@/layouts/EmptyLayout';
import MainLayout from '@/layouts/MainLayout';
import FreeLayout from '@/layouts/FreeLayout';
import {mapGetters} from "vuex";
export default {
  name: 'App',
  computed: {
    ...mapGetters(['profileGet']),
    layout: function(){
      console.log(this.$route)
      return (this.$route.meta.layout) + '-layout'
    },
    isLoggedIn: function() {
      return this.$store.getters.isLoggedIn;
    },
  },
  async mounted() {
    this.getProfile();
  },
  methods: {
    getProfile: function(){
      if(this.$store.getters.isLoggedIn){
        this.$store.dispatch('getProfile', this.$store.getters.user_id);
      }
    },
  },
  components: {
    EmptyLayout, MainLayout, FreeLayout
  }
}
</script>

<style>
@import "assets/css/style.css";
</style>
