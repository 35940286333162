<template>
  <section class="main">
    <div class="d-flex flex-wrap align-items-center justify-content-between">
      <div class="table-header">Список промокодов</div>
        <button class="btn btn-success" @click="openModal(null, 'createPromocode')">Создать промокод</button>
    </div>
    <table class="table table-hover table-bordered">
      <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Описание</th>
        <th scope="col">Код использования</th>
        <th scope="col">Возможность повтора <vue-custom-tooltip label="0 - для одного человека, 1 - для многих людей"><i class="far fa-question-circle"></i></vue-custom-tooltip></th>
        <th scope="col">Сумма скидки</th>
        <th scope="col">После какой суммы</th>
        <th scope="col">Регион</th>
        <th scope="col">Дата начала</th>
        <th scope="col">Дата конца</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in promocodesInfo" :key="item.id">
        <th scope="row">{{ index + 1 }}</th>
        <td>{{ item.description }}</td>
        <td>{{ item.code }}</td>
        <td>{{ item.repeat }} </td>
        <td v-if="item.type == 0">{{ item.amount }}%</td>
        <td v-else>{{ item.amount }} руб.</td>
        <td>{{ item.after_amount }}</td>
        <td>{{ item.region }}</td>
        <td>{{ item.date_start }}</td>
        <td>{{ item.date_end }}</td>
        <td class="actions">
          <a @click="openModal(item.id, 'editPromocode')">
            <i class="fas fa-pen"></i>
          </a>
        </td>
      </tr>
      </tbody>
    </table>
    <create-promocode v-show="this.$store.state.modal.createPromocode"/>
    <edit-promocode :id="selectPromocode" v-show="this.$store.state.modal.editPromocode"/>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import CreatePromocode from "../../components/modal/promocodes/create-promocode";
import EditPromocode from "../../components/modal/promocodes/edit-promocode";

export default {
  name: 'promocodes',
  components: {EditPromocode, CreatePromocode},
  data: function () {
    return {
      selectPromocode: 0,
    }
  },
  computed: {
    ...mapGetters(['promocodesInfo']),
  },
  async mounted() {
    await this.getPromocodesList();
  },
  methods: {
    getPromocodesList: function(){
      this.$store.dispatch('getListPromocode');
    },
    openModal: function(id=null, name){
      this.selectPromocode = id;
      this.$store.dispatch('openModal', name);
    },
  }
}
</script>